import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { showNotification } from "features/common/headerSlice";
import { useDispatch } from "react-redux";
import { setProductsTypeDetails, setProductCategoryDetails } from "app/redux/slices/productSlice";

const baseURL = process.env.REACT_APP_PUBLIC_BASE_URL
// const baseURL =  process.env.REACT_APP_PUBLIC_BASE_URL;
const queryClient = new QueryClient();

// Create a function-based API service
function createApiService() {
  const api = axios.create({
    baseURL,
    timeout: 1000 * 60 * 2, // Set a timeout of 5 seconds
    headers: {
      "Content-Type": "application/json",
    },
  });
  // Add an interceptor for token authorization
  api.interceptors.request.use(
    (config) => {
      // Retrieve the token from local storage or wherever you have it corpsd
      const token = localStorage.getItem("token"); // Replace with your actual token storage mechanism

      // If a token exists, add it to the request headers
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  // Generic function to make GET requests
  async function get(endpoint, params = {}) {
    try {
      const response = await api.get(endpoint, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Generic function to make POST requests
  async function post(endpoint, data = {}) {
    try {
      const response = await api.post(endpoint, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }


  // Generic function to make PATCH requests
  async function patch(endpoint, data = {}) {
    try {
      const response = await api.patch(endpoint, data);
      return response.data;
    } catch (error) {
      console.log(error)
      throw error;
    }
  }


  // Generic function to make PUT requests
  async function put(endpoint, data = {}) {
    try {
      const response = await api.put(endpoint, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Generic function to make DELETE requests
  async function remove(endpoint, user) {
    try {
      const response = await api.delete(endpoint, user);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Add additional custom API methods as needed
  async function login(data) {
    try {
      return await post("admins/login", data);
    } catch (error) {
      throw error;
    }
  }

  async function resendMail(data) {
    try {
      return await put("admins/resendmail", data);
      // return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function registerAdmin(data) {
    try {
      return await post("admins/register", data);
      // return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function getAllAdmins() {
    try {
      const response = await get("admins");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function getAdmin(id) {
    try {
      const response = await get(`admins/${id}/spec`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function getAdminRoles() {
    try {
      const response = await get("startup/adminroles");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function getAllSubAdmin() {
    try {
      const response = await get("admins/admins");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async function getDemoRequests() {
    try {
      const response = await get("admins/demo/request");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function getResetPasswordLink({ email }) {
    try {
      const response = await get("admins/password-link", { email });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function getUsersByType({ usertype }) {
    try {
      const response = await get(`admins/usertype?usertype=${usertype}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function getUsers({ page, limit }) {
    try {
      const response = await get(`admins/users?page=${page}&limit=${limit}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function getUser({ id }) {
    try {
      const response = await get(`admins/users/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function suspendUser(id) {
    try {
      const response = await patch(`admins/users/${id}/suspend`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function restoreUser(id) {
    try {
      const response = await patch(`admins/users/${id}/restore`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function getPlans(type, page, limit) {
    try {
      return await get(`admins/corporates?plan=${type}&page=${page}&limit=${limit}`);
      // return response.data;
    } catch (error) {
      throw error;
    }
  }

  
  async function corporateInfo(id) {
    try {
      const response = await get(`admins/corporates/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function getCorpEntities() {
    try {
      const response = await get(`admins/corporate/pre-registration`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async function getDeletedUser(id) {
    try {
      const response = await get(`admins/users/deleted/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function getUserDetails(id) {
    try {
      const response = await get(`admins/user/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function getSellers({ page, limit }) {
    try {
      const response = await get(`admins/seller?page=${page}&limit=${limit}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function getSellerDetails({ id }) {
    try {
      const response = await get(`admins/seller/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function suspendSeller(id) {
    try {
      return await put(`admins/seller/suspend/${id}`);
    } catch (error) {
      throw error;
    }
  }

    async function deleteSeller(id) {
    try {
      return await remove(`admins/seller/${id}`);
    } catch (error) {
      throw error;
    }
  }


  //restore seller
    async function restoreSeller(id) {
    try {
      return await put(`admins/seller/restore/${id}`);
    } catch (error) {
      throw error;
    }
  }


  async function getDashboardTopCardDetails(id) {
    try {
      const response = await get(`admins/admins/dashboardcards`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function AdminActivity() {
    try {
     return await get("admins/history");
    } catch (error) {
      throw error?.message;
    }
  }

  async function updateProfile(data) {
    try {
      const response = await patch("admins/profile", data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      return response;
    } catch (error) {
      throw error?.message;
    }
  }
  async function updatePassword(data) {
    try {
      const response = await put("admins/password", data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function updateSubAdmin(data) {
    try {
      const response = await put("admins/status", data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function updateAdminRole({role, id}) {
    try {
      const response = await put(`admins/${id}/role`, {role});
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function updateUsersStatus(data) {
    try {
      const response = await put("admins/status/users", data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function updateDemoRequestStatus(data) {
    try {
      const response = await put("admins/demo/request/status", data);
      return response;
    } catch (error) {
      throw error;
    }
  }
  async function upgradeCorporateUser({ id, data }) {
    try {
      const response = await put(`admins/corporate/${id}/upgrade`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function revokeCorporateUser({ id,}) {
    try {
      const response = await put(`admins/corporate/${id}/revoke`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function deleteRequest(id) {
    try {
      const response = await remove(`admins/demo/request/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function updateDemoDuration(duration) {
    try {
      const response = await api.patch(`admins/demo/request/duration`, {
        duration: duration,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async function getDemoDuration() {
    try {
      const response = await get(`corporate/demo/request/duration`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async function getAllProducts({ page = 1, limit = 50, status = "pending" }) {
    try {
      const response = await get(
        `admins/products/?page=${page}&limit=${limit}&status=${status}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function getProductTypes() {
    try {
      const response = await get(
        `startup/product-type`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function postProductType(payload) {
    try {
      const response = await post(
        `startup/product-type`, payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function updateProductType(payload) {
    try {
      const response = await put(
        `startup/product-type/${payload?._id}`, payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function getProductCategory(id) {
    try {
      const response = await get(
        `startup/categories/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async function getSingleProductCategory(id) {
    try {
      const response = await get(
        `startup/category/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function postProductCategory(payload) {
    try {
      const response = await post(
        `startup/category`, payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function editProductCategory(payload) {
    try {
      return await put(
        `startup/category/update/${payload?._id}`, payload
      );
    } catch (error) {
      throw error;
    }
  }

  async function updateProductStatus(payload) {
    try {
      const response = await put(`admins/products/status`, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async function getAllCategories() {
    try {
      const response = await get(`admins/categories`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async function setCategoryCommission(payload) {
    try {
      const response = await put(`admins/categories`, {
        update: {
          commission: payload,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  // LOGISTICS
  // invitation api
  async function logisticInvite(data) {
    try {
      const response = await post("logistics/logistic-invite", data);
      return response;
    } catch (error) {
      throw error;
    }
  }
  // get all invites
  async function getAllinvites() {
    try {
      const response = await get(`logistics/alllogistics`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  // Delete an invites
  async function deleteInvites(id) {
    try {
      const response = await remove(`logistics/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  // DELIVERY COST
  async function getAllDeliverycost() {
    try {
      const response = await get(`logistics/deliverycost`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  // Get by Id
  async function getSingleDeliverycost(id) {
    try {
      const response = await get(`logistics/deliverycost/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // update
  async function updateDeliverycost({ id, data }) {
    console.log("deliverycostID", id);
    console.log("deliverycostData", data);
    try {
      const response = await put(`logistics/editdeliverycost/${id}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }
  // ORDERSTATES
  async function getAllOrders() {
    try {
      const response = await get(`logistics/orders/allorder`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  //ordereditems 
  async function getOrderedItems({ page, limit }) {
    try {
      const response = await get(`mktplace/orderitems?page=${page}&limit=${limit}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }


  //ordereditems 
  async function getOrderedItem({ id }) {
    try {
      const response = await get(`mktplace/orderitems/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  // Get by Id
  async function getSingleOrder(id) {
    try {
      const response = await get(`logistics/orders/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  // Get by Id
  async function getSingleOrder2(id) {
    // console.log("this is orderID", id)
    try {
      const response = await get(`logistics/orders/order-items/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  // getOrderstats
  async function getOrderstats() {
    try {
      const response = await get(`logistics/stats/orderstats`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  // getLogisticsOrderStats
  async function getLogisticsOrderStats() {
    try {
      const response = await get(`logistics/stats/logisticorderstats`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  // getLogisticsOrderStats
  async function getLogisticsVotes() {
    try {
      const response = await get(`logistics/logisticvotes/allvotes`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  // update
  async function endPriceVote({ id, data }) {
    // console.log("deliverycostID", id);
    // console.log("deliverycostData", data);
    try {
      const response = await put(`logistics/pricevote/end/${id}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  //MarketPlace Demands
  async function getMarketDemands() {
    try {
      const response = await get(`mktplace/demands`);
      return response;
    } catch (error) {
      throw error?.response?.data || error;
    }
  }

  //MarketPlace Demands
  async function getAdminMarketOrders() {
    try {
      return await get(`admins/market/orders`);
      // return response;
    } catch (error) {
      throw error?.response?.data || error;
    }
  }

  async function getAdminMarketSingleOrder(id) {
    try {
      const response = await get(`admins/market/orders/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }


  //MarketPlace Demands
  async function getAdminMarketDemands() {
    try {
      const response = await get(`admins/market/demands`);
      return response;
    } catch (error) {
      throw error?.response?.data || error;
    }
  }

  //closed MarketPlace Demands

  async function getAdminClosedDemands(page, limit) {
    try {
      const response = await get(`admins/market/demands/closed?page=${page}&limit=${limit}`);
      return response;
    } catch (error) {
      throw error?.response?.data || error;
    }
  }


  //single market place dmand
  async function getSingleMarketDemand(id) {
    try {
      const response = await get(`admins/market/demand/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function getCompletedMarketDemand(id) {
    try {
      return await get(`admins/market/demand/closed/${id}`);
    } catch (error) {
      throw error;
    }
  }

  //
  async function getTransactions() {
    try {
      return await get(`admins/transactions`);
      // return response;
    } catch (error) {
      throw error?.response?.data || error;
    }
  }

  //single market demand suppliers
  async function getSingleDemandSuppliers(id) {
    try {
      const response = await get(`admins/market/demand/${id}/suppliers`);
      return response;
    } catch (error) {
      throw error?.response?.data || error;
    }
  }

  //accept single supplier
  async function acceptSupplier(payload) {
    try {
      return await put(`admins/market/supply/${payload?.id}`, payload);

    } catch (error) {
      throw error?.response?.data || error;
    }
  }

  //accept single supplier
  async function generateCheckoutCode(payload) {
    try {
      return await post(`admins/market/demands/generateCode`, payload);
    } catch (error) {

      throw error?.response?.data || error;
    }
  }

  //close demand
  async function closeMarketDemand(payload) {
    try {
      return await put(`admins/market/demand/${payload}/close`,);
    } catch (error) {

      throw error?.response?.data || error;
    }
  }

  async function getSimilairProducts({ seller, page }) {
    try {
      return await get(`admins/products/seller/${seller}?page=${page}`,);
    } catch (error) {

      throw error?.response?.data || error;
    }

  }



  async function getCountries() {
    try {
      return await get(`startup/countries`,);
    } catch (error) {

      throw error?.response?.data || error;
    }

  }

  async function getStates({ stateId }) {
    try {
      const resp = await get(`startup/state/${stateId}`,);
      return resp.data
    } catch (error) {

      throw error?.response?.data || error;
    }

  }

  //reject product
   async function rejectProduct(payload) {
    try {
      return await api.patch(`admins/products/${payload?.id}/reject`, payload);
      
    } catch (error) {

      throw error?.response?.data || error;
    }

  }

  async function fetchDeletedUsers() {
    try {
      const resp = await api.get(`admins/users/deleted`);
      return resp.data
      
    } catch (error) {

      throw error?.response?.data || error;
    }
  }


  async function deleteProduct(id) {
    try {
      return await api.delete(`products/${id}`,);
      
    } catch (error) {

      throw error?.response?.data || error;
    }

  }

  async function suspendProduct(payload) {
    try {
      return await api.patch(`admins/products/${payload?.id}/suspend`, payload);
      
    } catch (error) {

      throw error?.response?.data || error;
    }

  }

  

  // deleteProductImage
  async function deleteProductImage(payload) {
    // console.log(payload)
    try {
      return await api.delete(`products/${payload?.id}/single-image/${payload?.imageId}`);
      
    } catch (error) {

      throw error?.response?.data || error;
    }

  }


  // delete store Image
  async function deleteStoreImage(payload) {
    try {
      return await api.patch(`admins/seller/${payload?.seller}/store/image`, payload);
    } catch (error) {
      throw error?.response?.data || error;
    }

  }


  async function getlga({ stateId }) {
    try {
      const resp = await get(`startup/lga/${stateId}`,);
      return resp.data
    } catch (error) { 

      throw error?.response?.data || error;
    }

  }

  //Delete user
    async function deleteUser (id) {
    try {
      return await remove(`admins/users/${id}/paranoid`);
    } catch (error) { 

      throw error?.response?.data || error;
    }
  }

    //permanently Delete user
    async function permanentdeleteUser (id) {
    try {
      return await remove(`admins/users/${id}`);
    } catch (error) { 

      throw error?.response?.data || error;
    }
  }

// Delete Admin
  async function deleteAdmin (id) {
    try {
      return await remove(`admins/${id}/paranoid`);
    } catch (error) { 

      throw error?.response?.data || error;
    }

  }

  async function suspendAdmin (id) {
    try {
      return await patch(`admins/${id}/suspend`,);
     
    } catch (error) { 

      throw error?.response?.data || error;
    }

  }


  async function restoreAdmin (id) {
    try {
      return await patch(`admins/${id}/restore`,);
     
    } catch (error) { 

      throw error?.response?.data || error;
    }

  }

  return {
    get,
    put,
    post,
    patch,
    login,
    remove,
    getPlans,
    corporateInfo,
    getUsers,
    getUser,
    getDeletedUser,
    getSellers,
    deleteStoreImage,
    suspendUser,
    restoreUser,
    fetchDeletedUsers,
    getSellerDetails,
    suspendSeller,
    restoreSeller,
    updateProfile,
    getCountries,
    getStates,
    getlga,
    registerAdmin,
    resendMail,
    getAdminRoles,
    deleteRequest,
    getAllSubAdmin,
    getUserDetails,
    updateSubAdmin,
    updatePassword,
    getUsersByType,
    getAllProducts,
    getDemoRequests,
    getCorpEntities,
    getDemoDuration,
    updateUsersStatus,
    updateDemoDuration,
    updateProductStatus,
    upgradeCorporateUser,
    revokeCorporateUser,
    getResetPasswordLink,
    updateDemoRequestStatus,
    getDashboardTopCardDetails,
    logisticInvite,
    getAllinvites,
    deleteInvites,
    getAllDeliverycost,
    getSingleDeliverycost,
    updateDeliverycost,
    getAllOrders,
    getOrderedItems,
    getOrderedItem,
    getSingleOrder,
    getSingleOrder2,
    getOrderstats,
    getLogisticsOrderStats,
    getLogisticsVotes,
    endPriceVote,
    getAllCategories,
    setCategoryCommission,
    getMarketDemands,
    getSingleMarketDemand,
    getCompletedMarketDemand,
    getSingleDemandSuppliers,
    acceptSupplier,
    getAdminMarketDemands,
    getAdminMarketOrders,
    getAdminMarketSingleOrder,
    getAdminClosedDemands,
    generateCheckoutCode,
    getProductTypes,
    postProductType,
    getProductCategory,
    postProductCategory,
    editProductCategory,
    updateProductType,
    closeMarketDemand,
    getTransactions,
    getSimilairProducts,
    getSingleProductCategory,
    getAllAdmins,
    getAdmin,
    deleteProduct,
    rejectProduct,
    permanentdeleteUser,
    deleteProductImage,
    AdminActivity,
    deleteAdmin,
    suspendAdmin,
    restoreAdmin,
    updateAdminRole,
    deleteUser,
    deleteSeller,
    suspendProduct
  };
}

// Create a singleton instance of the API service
const api = createApiService();

export default api;

//API HOOKS
export const useCustomQuery = ({ queryKey, queryFn, options }) =>
  useQuery({
    queryKey: queryKey,
    queryFn: queryFn,
    retryOnMount: true,
    refetchInterval: 300000,
    refetchOnWindowFocus: true,
    refetchOnReconnect: "always",
    refetchIntervalInBackground: true,
    ...options,
  });

export const useCorporatePlans = ({type, page, limit, id}) => {
  const {
    data: corps,
    refetch: refetchCorps,
    isLoading: isCorpsLoading,
    isFetching,
  } = useCustomQuery({
    queryKey: ["getPlans", type, page, limit],
    queryFn: () => api.getPlans(type, page, limit),
    options:{
      enabled: !!type,
    }
  });


  const {
    data: corpInfo,
    refetch: refetchCorp,
    isLoading,
    isFetching: isFetchingCorp,
  } = useCustomQuery({
    queryKey: ["getCorporateInfo", id],
    queryFn: () => api.corporateInfo(id),
    options:{
      enabled: !!id
    }
  });

  return { corps, isCorpsLoading, refetchCorps, isFetching,
    corpInfo,
    refetchCorp,
    isLoading,
    isFetchingCorp
   };
};

export const useDemoRequests = () => {
  const dispatch = useDispatch();
  const {
    data: demos,
    refetch: refetchDemos,
    isLoading: isDemosLoading,
    isFetching,
  } = useCustomQuery({
    queryKey: ["getDemoRequests"],
    queryFn: () => api.getDemoRequests(),
  });

  const {
    data: duration,
    refetch: refetchDuration,
    isLoading: isGettingDuration,
  } = useCustomQuery({
    queryKey: ["getDemoDuration"],
    queryFn: () => api.getDemoDuration(),
  });

  const deleteRequest = useMutation(api.deleteRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries("getDemoRequests");
    },
  });

  const updateDemoDuration = useMutation(api.updateDemoDuration, {
    onSuccess: (data) => {
      dispatch(
        showNotification({
          message: data?.message,
          status: 1,
        })
      );
    },
    onError: (data) => {
      dispatch(
        showNotification({
          message: data?.message || 'error occured',
          status: 0,
        })
      );
    },
  });

  return {
    demos,
    duration,
    refetchDemos,
    isFetching,
    deleteRequest,
    isDemosLoading,
    refetchDuration,
    isGettingDuration,
    updateDemoDuration,
  };
};



export const useProducts = ({ page, limit, status, seller }) => {
  const dispatch = useDispatch();
  const {
    data,
    refetch: refetchProducts,
    isFetching: isFetchingProducts,
    isLoading: isProductsLoading,
  } = useCustomQuery({
    queryKey: ["getAllProducts", page],
    queryFn: () =>
      api.getAllProducts({ page: page, limit: limit, status: status }),
  });


  const {
    data: similairProducts,
    refetch: refetchSimProducts,
    isLoading: isSimiliarLoading,
  } = useCustomQuery({
    queryKey: ["OtherProducts", seller, page],
    options: {
      enabled: !!seller,
    },
    queryFn: () =>
      api.getSimilairProducts({ seller, page }),
  });

  const products = data?.product;
  const updateProductStatus = useMutation(api.updateProductStatus, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getAllProducts");
      dispatch(
        showNotification({
          message: data?.message,
          status: 1,
        })
      );
    },
    onError: (data) => {
      dispatch(
        showNotification({
          message: data?.message || 'error occured',
          status: 0,
        })
      );
    },
  });

  // console.log(similairProducts)
  return {
    data,
    products,
    refetchProducts,
    isProductsLoading,
    updateProductStatus,
    similairProducts: similairProducts?.data,
    refetchSimProducts,
    isSimiliarLoading,
    isFetchingProducts
  };
};



export const useCountries = ({ stateId, lgaId }) => {
  const {
    data,
    refetch,
    isFetching,
    isLoading,
  } = useCustomQuery({
    queryKey: ["getCountries",],
    queryFn: () =>
      api.getCountries(),
  });


  const {
    data: states,
    refetch: refetchStates,
    isLoading: isLoadingState,
  } = useCustomQuery({
    queryKey: ["getStates", stateId],
    queryFn: () =>
      api.getStates({ stateId }),
    options: {
      enabled: !!stateId
    }
  });

  const {
    data: lgas,
    refetch: refetchLga,
    isLoading: isLoadingLga,
  } = useCustomQuery({
    queryKey: ["getLga", lgaId],
    queryFn: () =>
      api.getlga({ stateId: lgaId }),
    options: {
      enabled: !!lgaId
    }
  });

  // console.log(states)

  return {
    data,
    refetch,
    isFetching,
    isLoading,
    lgas: lgas?.lga,
    isLoadingLga,
    refetchLga,
    refetchStates,
    isLoadingState,
    states: states?.states
  }
}



export const useActivities = () => {
  const {
    data,
    refetch,
    isFetching,
    isLoading,
  } = useCustomQuery({
    queryKey: ["useActivities",],
    queryFn: () =>
      api.AdminActivity(),
  });
console.log(data)

  return {
    data:data?.data,
    refetch,
    isFetching,
    isLoading,
  }
}

export const useSingleAdmins = (id) => {
  const dispatch = useDispatch()
  const {
    data,
    refetch,
    isFetching,
    isLoading,
  } = useCustomQuery({
    queryKey: ["getSingleAdmin", id],
    queryFn: () =>
      api.getAdmin(id),
    options: {
      enabled: !!id
    }
  });

  const suspendAdmin = useMutation(api.suspendAdmin, {
    onSuccess: (data) => {
      refetch()
      dispatch(
        showNotification({
          message: data?.message,
          status: 1,
        })
      );
    },
    onError: (data) => {
      console.log(data)
      dispatch(
        showNotification({
          message: data?.response?.data?.message || data?.message|| 'error occured',
          status: 0,
        })
      );
    },
  })


  const restoreAdmin = useMutation(api.restoreAdmin, {
    onSuccess: (data) => {
      refetch()
      dispatch(
        showNotification({
          message: data?.message,
          status: 1,
        })
      );
    },
    onError: (data) => {
      console.log(data)
      dispatch(
        showNotification({
          message: data?.message || data?.error || 'error occured',
          status: 0,
        })
      );
    },
  })

  return {
    data,
    refetch,
    isFetching,
    isLoading,
    suspendAdmin,
    restoreAdmin,
  }
}

export const useAdminRoles = () => {
const dispatch = useDispatch()
  const {
    data,
    refetch,
    isFetching,
    isLoading,
  } = useCustomQuery({
    queryKey: ["getAdminRoles"],
    queryFn: () =>
      api.getAdminRoles(),
  });

                            
  const updateRole = useMutation(api.updateAdminRole, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({queryKey:['getSingleAdmin', data?.data?._id]});
      dispatch(
        showNotification({
          message: data?.message,
          status: 1,
        })
      );
    },
    onError: (data) => {
      console.log(data)
      dispatch(
        showNotification({
          message: data?.message || data?.error || 'error occured',
          status: 0,
        })
      );
    },
  })

  return {
    data,
    refetch,
    isFetching,
    isLoading,
    updateRole
  }
}

export const useUsers = ({ id, page, limit }) => {
  // const dispatch = useDispatch()
  const {
    data,
    refetch,
    isFetching,
    isLoading,
  } = useCustomQuery({
    queryKey: ["getAllusers", page, limit],
    queryFn: () =>
      api.getUsers({ page, limit }),
  });

  const {
    data: user,
    refetch: refetchUser,
    isLoading: isLoadingUser,
  } = useCustomQuery({
    queryKey: ["getUser", id],
    options: {
      enabled: !!id
    },
    queryFn: () =>
      api.getUser({ id }),
  });


  const {
    data: deleteduser,
    refetch: refetchDeletedUser,
    isLoading: isLoadingDeltedUser,
  } = useCustomQuery({
    queryKey: ["getDeletedUser", id],
    options: {
      enabled: !!id
    },
    queryFn: () =>
      api.getDeletedUser(id),
  });



  return {
    users: data?.users,
    data,
    isLoading,
    isFetching,
    refetch,
    user,
    refetchUser,
    isLoadingUser,
    deleteduser,
    isLoadingDeltedUser,
    refetchDeletedUser
  }

}

export const useUsersType = ({ usertype, page, limit }) => {
  const {
    data,
    refetch,
    isFetching,
    isLoading,
  } = useCustomQuery({
    queryKey: ["getUsersByType", usertype, page, limit],
    queryFn: () =>
      api.getUsersByType({ usertype, page, limit }),
    options:{
      enabled: !!usertype
    }
  });

  return {
    data,
    isLoading,
    isFetching,
    refetch
  }

}

export const useAdmins = () => {
  const dispatch = useDispatch()
  const {
    data,
    refetch,
    isFetching,
    isLoading,
  } = useCustomQuery({
    queryKey: ["getAdmins",],
    queryFn: () =>
      api.getAllAdmins(),
  });


  const registerAdmin = useMutation(api.registerAdmin, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getAdmins");
      refetch()
      dispatch(
        showNotification({
          message: data?.message || 'created successfully',
          status: 1,
        })
      );
    },
    onError: (data) => {
      console.log(data)
      dispatch(
        showNotification({
          message: data?.message || 'error occured',
          status: 0,
        })
      );
    },
  });

  const resendMail = useMutation(api.resendMail, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getAdmins");
      refetch()
      dispatch(
        showNotification({
          message: data?.message,
          status: 1,
        })
      );
    },
    onError: (data) => {
      dispatch(
        showNotification({
          message: data?.message || 'error occured',
          status: 0,
        })
      );
    },
  })

  const updateAdmin = useMutation(api.updateSubAdmin, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getAdmins");
      refetch()
      dispatch(
        showNotification({
          message: data?.message,
          status: 1,
        })
      );
    },
    onError: (data) => {
      dispatch(
        showNotification({
          message: data?.message || 'error occured',
          status: 0,
        })
      );
    },
  });

  return {
    data,
    refetch,
    isFetching,
    isLoading,
    registerAdmin,
    updateAdmin,
    resendMail
  }
}

export const useProductCategory = (id) => {
  const dispatch = useDispatch();
  const {
    data,
    refetch,
    isFetching: isFetchingCategory,
    isLoading,
  } = useCustomQuery({
    queryKey: ["getProductCategory", id],
    queryFn: () =>
      api.getProductCategory(id),
    options: {
      enabled: !!id
    }
  });

  const postProductCategory = useMutation(api.postProductCategory, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({queryKey:["getAllProductsCategory"]});
      dispatch(
        showNotification({
          message: data?.message,
          status: 1,
        })
      );
    },
    onError: (data) => {
      dispatch(
        showNotification({
          message: data?.message || 'error occured',
          status: 0,
        })
      );
    },
  });

  const updateProductCategory = useMutation(api.editProductCategory, {
    onSuccess: (data) => {
      // queryClient.invalidateQueries({queryKey:["getCategory",]});
      refetch()
      dispatch(setProductCategoryDetails(data?.category))
      dispatch(
        showNotification({
          message: data?.message,
          status: 1,
        })
      );
    },
    onError: (data) => {
      dispatch(
        showNotification({
          message: data?.message || 'error occured',
          status: 0,
        })
      );
    },
  });

  return {
    data,
    isLoading,
    postProductCategory,
    updateProductCategory,
    isFetchingCategory,
    refetch
  };
};

export const useSingleProductCategory = ({id}) => {
  const {
    data,
    refetch,
    isFetching: isFetchingCategory,
    isLoading,
  } = useCustomQuery({
    queryKey: ["getCategory", id],
    queryFn: () =>
      api.getSingleProductCategory(id),
    options: {
      enabled: !!id
    }
  });

  return {
    data,
    isLoading,
    isFetchingCategory,
    refetch
  };
};
export const useProductTypes = () => {
  const dispatch = useDispatch();
  const {
    data,
    refetch,
    isFetching: isFetchingType,
    isLoading,
  } = useCustomQuery({
    queryKey: ["getAllProductsTypes"],
    queryFn: () =>
      api.getProductTypes(),
  });

  const postProductType = useMutation(api.postProductType, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getAllProductsTypes");
      refetch()
      dispatch(
        showNotification({
          message: data?.message,
          status: 1,
        })
      );
    },
    onError: (data) => {
      dispatch(
        showNotification({
          message: data?.message || 'error occured',
          status: 0,
        })
      );
    },
  });

  const updateProductType = useMutation(api.updateProductType, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getAllProductsTypes");
      refetch()
      dispatch(setProductsTypeDetails(data))
      dispatch(
        showNotification({
          message: 'update successful!',
          status: 1,
        })
      );
    },
    onError: (data) => {
      dispatch(
        showNotification({
          message: data?.message || 'error occured',
          status: 0,
        })
      );
    },
  });


  return {
    data,
    isLoading,
    postProductType,
    updateProductType,
    isFetchingType,
    refetch
  };
};


export const useSellerdetails = ({ id }) => {
  const dispatch = useDispatch()
  const {
    data: seller,
    refetch: refetchSeller,
    isLoading: isSellerLoading,
    isFetching,
  } = useCustomQuery({
    queryKey: ["getSellerDetails", id],
    options: {
      enabled: !!id
    },
    queryFn: () => api.getSellerDetails({ id }),
  });


  const suspendSeller = useMutation(api.suspendSeller, {
    onSuccess: (data) => {
      refetchSeller()
      dispatch(
        showNotification({
          message: data?.message || 'update successful!',
          status: 1,
        })
      );
    },
    onError: (data) => {
      dispatch(
        showNotification({
          message: data?.message || 'error occured',
          status: 0,
        })
      );
    },
  });



  return {
    isFetching,
    seller,
    refetchSeller,
    isSellerLoading,
    suspendSeller
  };

}

export const useSellers = ({ page, limit, }) => {
  const {
    data,
    refetch: refetchSellers,
    isLoading: isSellersLoading,
    isFetching
  } = useCustomQuery({
    queryKey: ["getSellers", page, limit],
    queryFn: () => api.getSellers({ page, limit }),
  });
  return {
    data,
    sellers: data?.sellers,
    refetchSellers,
    isSellersLoading,
    isFetching,
  };
};

export const useOrderedItems = ({ page, limit, id }) => {
  const {
    data,
    refetch,
    isLoading,
    isFetching,
  } =
    useCustomQuery({
      queryKey: ["getOrderItems", page, limit],
      queryFn: () => api.getOrderedItems({ page, limit }),
    });

  const {
    data: orderItem,
    refetch: refetchitem,
    isLoading: isLoadingItem,
    isError,
    error
  } =
    useCustomQuery({
      queryKey: ["getOrderItem", id],
      options: {
        enabled: !!id
      },
      queryFn: () => api.getOrderedItem({ id }),
    });

  return {
    orders: data?.orders,
    data,
    refetch,
    isLoading,
    isFetching,
    isLoadingItem,
    isError,
    error,
    refetchitem,
    orderItem
  };
}


export const useSingleOrderedItems = ({ id }) => {

  const {
    data: orderItem,
    refetch: refetchitem,
    isLoading: isLoadingItem,
    isError,
    error
  } =
    useCustomQuery({
      queryKey: ["getOrderItem", id],
      options: {
        enabled: !!id
      },
      queryFn: () => api.getOrderedItem({ id }),
    });

  return {
    isLoadingItem,
    isError,
    error,
    refetchitem,
    orderItem
  };
}

export const useOrders = ({ page, limit, id }) => {

  const {
    data: order,
    refetch: refetchOrder,
    isLoading: isSpin,
    isFetching: reFetchingOrder,
    error: orderError,
    isError: isOrderError,
  } = useCustomQuery({
    queryKey: ["getAdminMarketSingleOrder", id],
    queryFn: () =>
      api.getAdminMarketSingleOrder(id),
    options: {
      enabled: !!id
    }
  });


  const {
    data,
    refetch,
    isLoading,
    isFetching
  } = useCustomQuery({
    queryKey: ["useMarketOrders"],
    queryFn: () => api.getAdminMarketOrders({ page, limit }),
  });
  return {
    data,
    refetch,
    isLoading,
    isFetching,
    order,
    isSpin,
    refetchOrder,
    reFetchingOrder,
    orderError,
    isOrderError
  };
};

export const useMarketDemands = ({ page, limit }) => {
  const {
    data,
    refetch: refetchDemands,
    isLoading,
  } = useCustomQuery({
    queryKey: ["useMarketDemands"],
    queryFn: () => api.getAdminMarketDemands({ page, limit }),
  });
  return {
    data,
    refetchDemands,
    isLoading,
  };
};


export const useClosedMarketDemands = (limit, page,) => {
  const {
    data,
    refetch: refetchDemands,
    isLoading,
    isFetching,
    isFetched
  } = useCustomQuery({
    queryKey: ["useCloseMarketDemands", page, limit],
    queryFn: () => api.getAdminClosedDemands(page, limit),
  });
  return {
    data,
    refetchDemands,
    isLoading,
    isFetching
  };
};

export const useCompletedMarketDemand = (id) => {
  let {
    data,
    refetch,
    isLoading,
    isError,
    error,
    isFetching
  } = useCustomQuery({
    queryKey: ["useCompletedMarketDemand", id],
    queryFn: () => api.getCompletedMarketDemand(id),
    options: {
      enabled: !!id,

    }
  });

  ///
  return {
    data,
    demand: data?.data,
    refetch,
    isLoading,
    isFetching,
    error,
    isError
  };
};

export const useSingleMarketDemand = (id) => {
  const dispatch = useDispatch();
  let {
    data,
    refetch,
    isLoading,
    isError,
    error
  } = useCustomQuery({
    queryKey: ["useSingleMarketDemand"],
    queryFn: () => api.getSingleMarketDemand(id),
    options: {
      enabled: !!id,
    }
  });

  const closeUnfillDemands = useMutation(api.closeMarketDemand, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: 'useMarketDemands' });
      dispatch(
        showNotification({
          message: data?.message,
          status: 1,
        })
      );
    },
    onError: (data) => {
      dispatch(
        showNotification({
          message: data?.message || 'error occured',
          status: 0,
        })
      );
    },
  });

  ///
  return {
    data,
    demand: data?.data,
    refetch,
    isLoading,
    error,
    isError,
    closeUnfillDemands
  };
};

export const useDashboardCard = () => {
  // 
  let {
    data,
    refetch,
    isLoading,
  } = useCustomQuery({
    queryKey: ["useTransactions"],
    queryFn: () => api.getTransactions(),
    options: {
    }
  });

  ///
  return {
    transaction: data?.data,
    transactionRefresh: refetch,
    isTransaction: isLoading,

  };
}

export const useSingleDemandSuppliers = (id) => {
  // 
  let {
    data,
    refetch,
    isLoading,
    isError,
    error,
    isFetching
  } = useCustomQuery({
    queryKey: ["getSingleDemandSuppliers", id],
    queryFn: () => api.getSingleDemandSuppliers(id),
    options: {
      enabled: !!id,
    }
  });

  ///
  return {
    data,
    suppliers: data?.data,
    refetch,
    isLoading,
    error,
    isError,
    isFetching
  };
}

export const useAcceptSupplier = () => {
  const dispatch = useDispatch();

  const { isLoading, mutateAsync, isSuccess} = useMutation(api.acceptSupplier, {
    onSuccess: (data) => {
      // console.log(data)
      queryClient.invalidateQueries({ queryKey: ["getSingleDemandSuppliers"] });
      dispatch(
        showNotification({
          message: data?.message,
          status: 1,
        })
      );
    },
    onError: (data) => {
      console.log(data)
      dispatch(
        showNotification({
          message: data?.message || 'error occured',
          status: 0,
        })
      );
    },
  });

  return {
    isLoading,
    mutateAsync,
    isSuccess
  };
};


export const useGenerateCode = () => {
  const dispatch = useDispatch();

  const { isLoading, mutateAsync, } = useMutation(api.generateCheckoutCode, {
    onSuccess: (data) => {
      console.log(data)
      queryClient.invalidateQueries("getSingleDemandSuppliers");
      dispatch(
        showNotification({
          message: data?.message,
          status: 1,
        })
      );
    },
    onError: (data) => {
      console.log(data)
      dispatch(
        showNotification({
          message: data?.message || 'error occured',
          status: 0,
        })
      );
    },
  });

  return {
    isSpinning: isLoading,
    mutateAsync
  };
};